import { createStore } from "vuex";
import { auth } from "/src/store/auth.module.js";
import { cart } from "/src/store/cart.module.js";
import { checkout } from "/src/store/checkout.module.js";

const store = createStore({
  modules: {
    auth,
    cart,
    checkout,
  },
});

export default store

