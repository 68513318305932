<script setup>
import { removeNonDigits, slugify } from "@/utilites/helpers";
import CartDropDown from "@/components/cart/CartDropDown.vue";
</script>

<template>
  <div v-if="isSearch" class="search-overly"></div>
  <header :class="{'home-header': $route.name === 'home'}">
    <div class="header container">
      <button @click="openMenu" class="menu-btn" aria-label="Open Menu">
        <img src="@/assets/images/menu.svg" alt="Navigation" />
      </button>
      <nav class="menu">
        <div class="drawer">
          <ul>
            <li v-for="item in filteredHeaderNav" :key="item.id">
              <a 
              :href="item.url" 
              :target="item.new_tab ? '_blank' : '_self'"
              :class="{ active: isRouteActive(item.url) }"
              >
                {{ item.label.toUpperCase() }}
              </a>
              <!-- <router-link
                :to="item.url"
                :class="{ active: isRouteActive(item.url) }"
                :target="item.new_tab ? '_blank' : '_self'"
              >
                {{ item.label.toUpperCase() }}
              </router-link> -->
            </li>
          </ul>
          <!-- Mobile Drawer Started -->
          <div
            v-if="menuVisible"
            class="menu drawer-visible"
            style="overflow: auto"
          >
            <div class="drawer-header">
              <button v-if="menuVisible" @click="closeMenu" class="close-btn">
                <img src="@/assets/images/close.svg" alt="Close | Pregame" />
              </button>
              <div class="logo">
                <a :href="'/'"
                  ><img :src="settings.header_logo" alt="PreGame"
                /></a>
              </div>
            </div>
            <ul>
              <li v-for="item in filteredHeaderNav" :key="item.id">
                <a :href="item.url" 
                :target="item.new_tab ? '_blank' : '_self'"
                :class="{ active: isRouteActive(item.url) }"
                >
                  {{ item.label.toUpperCase() }}
                </a>
              </li>
              <li v-if="$isVisible == 1"><a v-if="!isLoggedIn" :href="'/login'">LOG IN</a></li>
              <li v-if="$isVisible == 1"><a v-if="isLoggedIn" :href="'/logout'">LOGOUT</a></li>
              <li class="drawer-space"></li>
              <li
                class="drawer-social"
                v-for="social in settings.social_links.slice().reverse()"
                :key="social"
              >
                <a
                  :href="social.url"
                  :target="social.new_tab ? '_blank' : '_self'"
                  >{{ social.label }}</a
                >
              </li>
              <li class="drawer-space"></li>
              <li class="drawer-social">
                <a :href="'mailto:' + settings.contact_email">{{
                  settings.contact_email
                }}</a>
              </li>
              <li class="drawer-social">
                <a :href="'tel:' + removeNonDigits(settings.contact_phone)"
                  >{{ settings.contact_phone }}</a
                >
              </li>
              <li class="drawer-social">
                <a
                  :href="
                    'https://www.google.com/maps/place/' +
                    slugify(settings.contact_address)
                  "
                  target="_blank"
                  >{{ settings.contact_address }}</a
                >
              </li>
            </ul>
          </div>
          <!-- Mobile Drawer ended -->
        </div>
      </nav>
      <div class="header-right-details">
        <div class="logo">
          <a :href="'/'"><img :src="settings.header_logo" alt="PreGame" /></a>
        </div>
        <div class="login">
          <a class="search" @click="toggleSearchBar"
            >SEARCH <img :src="src" alt="Search | Pregame" style="display: inline; margin: 0 5px 0 4px; width: 14px" />
          </a>

          <a v-if="!isLoggedIn && $isVisible == 1" :href="'/login'" class="hidden lg:block"
            >LOG IN</a
          >
          <a
            ref="dropdown"
            v-if="isLoggedIn && $isVisible == 1"
            @click="dropdownHideShow"
            class="cursor-pointer dropdownHideShow"
          >
            <div class="user-logged-in-desktop">
              <div class="wave">
                <img src="@/assets/images/wave.png" :alt="'Hello' + getName" />
                {{ getName }}
              </div>
              <img
                src="@/assets/images/arrow-down2.svg"
                alt="arrow"
                class="dropdown-arrow"
              />
            </div>
            <div class="user-logged-in-mobile">
              <div class="wave">
                <img src="@/assets/images/wave.png" :alt="getNameForMobile" />
                <p>{{ getNameForMobile }}</p>
              </div>
            </div>
            <div v-if="!isHide" ref="dropdown" class="dropdown">
              <img
                src="@/assets/images/dropdown-before.png"
                class="dropdown-before"
                alt=""
              />
              <p>
                <a :href="'/user/profile'"
                  ><img src="@/assets/images/user.png" alt="User Accounts | Pregame" class="icon-user"/> ACCOUNTS</a
                >
              </p>
              <p>
                <a :href="'/logout'" class="logout"
                  ><img src="@/assets/images/logout.png" alt="logout | pregame" /> LOGOUT</a
                >
              </p>
            </div>
          </a>
          <div class="cursor-pointer header-cart" v-if="$isVisible == 1">
            <a class="cart-count" @click="toggleCartDropdown">
              <img
                src="@/assets/images/shopping-cart.svg"
                alt="Cart | Pregame"
              />
              <span>{{ getCartCount() || 0 }}</span>
            </a>
            <CartDropDown
              v-if="isCartDropDown"
              @close-cart-dropdown="toggleCartDropdown"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isSearch">
      <SearchInput :toggleSearchBar="toggleSearchBar"></SearchInput>
    </div>
  </header>
</template>
<script>
import { useRoute } from "vue-router";
import SearchInput from "../inputs/SearchInput.vue";

export default {
  props: {
    items: Array,
    settings: Object,
  },
  data() {
    return {
      src: require("@/assets/images/search.svg"),
      isSearch: false,
      isHide: true,
      isCartDropDown: false,
      isLogin: false,
      Name: "",
      menuVisible: false,
    };
  },
  methods: {
    changeTheme(currentTheme) {
      if (currentTheme === null) {
        currentTheme = "dark";
      }
      document.documentElement.setAttribute("data-theme", currentTheme);
    },
    dropdownHideShow() {
      this.isHide = !this.isHide;
      if (this.isCartDropDown === true) {
        this.isCartDropDown = false;
      }
    },
    toggleSearchBar() {
      this.isSearch = !this.isSearch;
      this.src = this.isSearch
        ? require("@/assets/images/close.svg")
        : require("@/assets/images/search.svg");
    },
    toggleCartDropdown() {
      this.isCartDropDown = !this.isCartDropDown;
      if (this.isHide === false) {
        this.isHide = true;
      }
      if (this.isCartDropDown === true) {
        this.getUserCartFromAccount();
      }
    },
    openDropdown() {
      this.isCartDropDown = true;
      document.addEventListener("click", this.closeCartDropdown);
    },
    isRouteActive(route) {
      const currentRoute = useRoute();
      return currentRoute.path === route;
    },
    openMenu() {
      this.menuVisible = true;
    },
    closeMenu() {
      this.menuVisible = false;
    },
  },
  mounted() {
    this.changeTheme(this.settings.theme);
  },
  computed: {
    isLoggedIn() {
      const getType = this.$store.getters["auth/getGuestLoginUserStatus"];
      if (getType !== "guest") {
        return this.$store.getters["auth/getLoginUserStatus"];
      }

      return null;
    },
    getName() {
      return this.$store.getters["auth/getLoginUserName"];
    },
    getNameForMobile() {
      const name = this.$store.getters["auth/getLoginUserName"];
      const initials = name
        .split(" ")
        .map((word) => word.charAt(0))
        .join("");

      return initials.toUpperCase(); // Convert to uppercase if needed
    },
    filteredHeaderNav() {
      const items = this.items;
      return items.filter((item) => item.is_header === true && item.disabled !== true);
    },
  },
};
</script>
