// modalConfig.js
export const modalConfig = {
  transitionTime: 200,
  animationType: 'slideDown',
  modalStyle: {
    padding: '5rem 2rem',
    align: 'center',
    'z-index': 201
  },
  overlayStyle: {
    'background-color': 'rgba(0, 0, 0, .5)',
    'z-index': 200
  }
};
