import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL


class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'auth/login', {
        email: user.email,
        password: user.password
      })
      .then(response => {

        if (response.data.StatusCode ==1 && response.data.Data.AccessToken ) {
          localStorage.setItem('user', JSON.stringify(response.data.Data));
          return;
        }
        throw response.data;
      });
  }

  socialLogin(user) {
    return axios
      .post(API_URL + 'auth/social-login', user)
      .then(response => {

        if (response.data.StatusCode ==1 && response.data.Data.AccessToken ) {
          localStorage.setItem('user', JSON.stringify(response.data.Data));
          return response.data.Data;
        }
        throw response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  forgotPassword(user) {
    return axios.post(API_URL + 'signup', {
      email: user.email,
    });
  }
}

export default new AuthService();