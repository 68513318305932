import axios from 'axios';
import authHeader from '/src/services/auth-header.js';
const API_URL = process.env.VUE_APP_API_URL

class CartService {
  /**
   * function to get the cart listing.
   *
   * @param {type} id - The ID of the shipping address
   * @return {type} The result of the axios GET request
   */
  getCartListing(id) {
    return axios.get(API_URL + 'cart?shippingAddressId='+id, { headers: authHeader() });
  }

  /**
   * addToCart function adds the given cartItem to the cart using a POST request to the API_URL + 'cart/add-cart' endpoint with the provided cartItem and authentication headers.
   *
   * @param {type} cartItem - the item to be added to the cart
   * @return {type} the response from the POST request
   */
  addToCart(cartItem) {
    return axios.post(API_URL + 'cart/add-cart', cartItem, { headers: authHeader() });
  }

  /**
   * updateCart - updates a cart item using axios
   *
   * @param {Object} cartItem - the cart item to be updated
   * @return {Promise} the updated cart item
   */
  updateCart(cartItem) {
    return axios.put(API_URL + 'cart/update-cart-item', cartItem, { headers: authHeader() });
  }

  /**
   * removeCart function removes an item from the cart.
   *
   * @param {type} itemId - the ID of the item to be removed from the cart
   * @return {type} the result of the delete operation
   */
  removeCart(itemId) {
    const config = {
      headers: authHeader(),
    };
    
    return axios.delete(API_URL + 'cart/delete-cart-item?ItemId=' + itemId.Id+ '', config);
  }
}

export default new CartService();