import axios from 'axios';

const clientId = process.env.VUE_APP_PAYPAL_CLIENT_ID;
const clientSecret = process.env.VUE_APP_PAYPAL_CLIENT_SECRET_ID;
let paypalURL = process.env.VUE_APP_PAYPAL_URL;

class PaypalService {
  constructor() {
    this.accessToken = null;
  }

  async initializeAccessToken() {
    try {
      const auth = await axios.post(paypalURL + "/v1/oauth2/token",
        new URLSearchParams({
          'grant_type': 'client_credentials'
        }),
        {
          headers: {
            "Accept": "application/json",
            "Accept-Language": "en_US",
            "Content-Type": "application/x-www-form-urlencoded"
          },
          auth: {
            username: clientId,
            password: clientSecret
          }
        }
      );
      this.accessToken = auth.data.access_token;
    } catch (error) {
      console.error("Error initializing access token:", error);
      throw error;
    }
  }

  async refundPayment(order_id,amount) {
    try {
      // Make sure access token is generated
      await this.initializeAccessToken();
      const capture = await axios.post(paypalURL + `/v2/payments/captures/${order_id}/refund`, {
        "amount": {
        "value": amount,
        "currency_code": "USD"
    },
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.accessToken}`,
        }
      });

      return capture.data;
    } catch (error) {
      console.error("Error refunding payment:", error);
      throw error;
    }
  }

   async createOrder(purchaseAmount) {
   try {
      await this.initializeAccessToken();
      const url = `${paypalURL}/v2/checkout/orders`;
      const response2 = await fetch(url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        body: JSON.stringify({
          intent: "CAPTURE",
          purchase_units: [
            {
              amount: {
                currency_code: "USD",
                value: purchaseAmount,
              },
            },
          ],
        }),
      });
      const data = await response2.json();
      return data;
   } catch (error) {
        throw new Error(error.message);
    }
   }
	async capturePayment(orderId) {
    await this.initializeAccessToken();
    const url = `${paypalURL}/v2/checkout/orders/${orderId}/capture`;
    const response = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
    const data = await response.json();
    return data;
  }
}

export default new PaypalService();
