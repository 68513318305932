export default function authHeader() {
  let user = JSON.parse(localStorage.getItem('user'));
  if (user && user.AccessToken) {
    localStorage.removeItem('guestToken');
    localStorage.removeItem('sessionId');
    return { 'Authorization': 'Bearer '  + user.AccessToken };
  } else {
    const guestToken = localStorage.getItem('guestToken');
    if (guestToken !== null) {
      return { 'Authorization': 'Bearer '  + guestToken };
    }
    return {};
  }
}