<template>
  <div></div>
</template>

<script>
import { useToast } from "vue-toastification";

export default {
  name: "ToastNotification",
  props: {
    message: String,
    type: { 
      type: String,
      default: 'default'
    },
    timeout: { 
      type: Number,
      default: 5000
    }
  },
  methods: {
    showToast() {
      const toast = useToast();
      toast(this.message, {
        type: this.type,
        position: "top-right",
        timeout: Number(this.timeout),
        closeOnClick: false,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: true,
        closeButton: "button",
        icon: true,
      });
    }
  },
  mounted() {
    this.showToast();
  }
};
</script>