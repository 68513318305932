import Butter from "buttercms";
export let butterCMS = undefined;
try {
  const apiKey = String(process.env.VUE_APP_BUTTER_CMS_API_KEY);
  const preview = Boolean(process.env.VUE_APP_BUTTER_CMS_PREVIEW);
  butterCMS = Butter(apiKey, preview);

} catch (error) {
  console.error(error);
}
