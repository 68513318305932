import cartService from "@/services/cart.service";
import { openModal } from "@kolirt/vue-modal";
import Modal from "@/components/modals/Modal.vue";
import { showOverlay } from "../helpers";
import authHeader from "@/services/auth-header";
import shipstationService from "@/services/shipstation.service";
export default {
    methods: {
        getUserCartFromAccount(addressId, requestType) {
            let id = addressId || 0;
            const userId = this.$store.getters["auth/getUserId"] || localStorage.getItem('sessionId');
            id = this.checkAddressIdforCartListing(id);
            if (userId) {
                cartService.getCartListing(id).then((response) => {
                if (response.data.StatusCode === 1 && response.data.Data.length > 0) {
                  const cartdata = response.data.Data;
                  localStorage.removeItem("cartItems");
                  this.$store.dispatch("cart/resetState")
                  cartdata.forEach((item) => {
                    const cartItem = {
                      itemId       : item.ItemId,
                      id           : item.Id,
                      productId    : item.ProductId,
                      userId       : userId,
                      quantity     : item.Quantity,
                      price        : item.Price,
                      taxPercentage: item.TaxRatePercentage,
                      taxAmount    : item.TaxAmount,
                      priceInclTax : item.PriceInclTax,
                      name         : item.Name,
                      colorId      : item.ColorId,
                      colorRgb     : item.ColorRgb,
                      sizeId       : item.SizeId,
                      sizeText     : item.SizeText,
                      image        : item.Image,
                      IsTaxFixed   : item.IsTaxFixed,
                      inStock      : (item.InStock === null) ? true : item.InStock,
                      currentQty   : item.CurrentQty,
                      minCartItem  : item.MinCartItem,
                      maxCartItem  : item.MaxCartItem,
                      shippingCost : this.getShippingCost(),
                      request      : requestType === 'manual' ? 'manual' : null
                    };
                    this.cartTotal += item.Price * item.Quantity;
                    this.cartTotal += item.TaxAmount || 0;
                    this.$store.dispatch('cart/addToCart', cartItem);              
                  });
                  this.$store.dispatch('cart/asyncUpdateCartTotal', this.cartTotal);
                }

                
              })
            }
        },
        checkAddressIdforCartListing(id) {
          if (id === 0 && (this.$route.name === 'checkout' || this.$route.name === 'guestCheckout')) {
            const checkoutData = this.$store.getters['checkout/getCheckoutData'];
            if (checkoutData !== null) {
              const addressData = checkoutData.addressData;
              if (addressData !== null) {
                return addressData.id
              }
            }
          }
          
          return id;
        },
        getCartSubTotal() {
          const total = this.$store.getters["cart/cartTotal"];
    
          return (total > 0) ? total.toFixed(2) : 0;
        },
        getShippingCost() {
          const shippingCost = this.$store.getters["checkout/getDeliveryPrice"];
    
          return (shippingCost > 0) ? shippingCost.toFixed(2) : 0;
        },
        getCartTotal() {
          const total        = this.$store.getters["cart/cartTotal"];
          const totalTax     = Number(this.getCartTaxTotal());
          const shippingCost = Number(this.getShippingCost());
          const totalwithTax = Number(total+shippingCost+totalTax);
    
          return (total > 0) ? totalwithTax.toFixed(2) : 0;
        },
        getCartTotalExclTax() {
          const total = this.$store.getters["cart/cartTotal"];
    
          return (total > 0) ? total.toFixed(2) : 0;
        },
        getCartTaxTotal() {
          const totalTax = this.$store.getters["cart/cartTaxAmount"];
    
          return (totalTax > 0) ? totalTax.toFixed(2) : 0;
        },
        getUserCartListing() {
          return (this.$store.state.cart.cartItems.length > 0) ?
            this.$store.state.cart.cartItems.filter(item => item.userId === this.getUserId()).slice().reverse() : [];
        },

        getUserId() {
          const userId = this.$store.getters["auth/getUserId"];
          
          return (userId) ? userId : localStorage.getItem("sessionId");
        },

        cartTaxPercentageFixed() {
          const cartTaxType = this.$store.getters["cart/getTaxTypeAndValue"];
          if (cartTaxType) {
            const taxValue = (cartTaxType.taxValue !== undefined) ? cartTaxType.taxValue : 0;
            const taxType = (cartTaxType.taxType !== undefined) ? cartTaxType.taxType : false;
            if (taxValue >0) {
              if (taxType === true) {
                return `(${taxValue.toFixed(1)}%)`;
              } else {
                return `($${taxValue.toFixed(2)} Fixed)`;
              }
            }
          }
        },
        getCartCount() {
          const userId =
            this.$store.getters["auth/getUserId"] ||
            localStorage.getItem("sessionId");

          return this.$store.state.cart.cartItems.length > 0
            ? this.$store.state.cart.cartItems.filter(
                (item) => item.userId === userId
              ).length
            : 0;
        },
        generateOrderID(orderNumber, length = 6) {
          if (orderNumber !== null) {
            length = (orderNumber.toString().length > length) ? length + 2 : length;
            const paddedNumber = orderNumber.toString().padStart(length, '0');
    
            return `#${paddedNumber}`;
          }
      },
      generateTransactionID() {
        // Generate a timestamp string
        const timestamp = new Date().getTime().toString();
  
        // Generate a random string (you can customize the length as needed)
        const randomString = Math.random().toString(36).substring(2, 10);
  
        // Combine timestamp and random string to create the transaction ID
        const transactionId = 'PG-'+timestamp + '-' + randomString;
  
        return transactionId.toLowerCase();
      },
      checkCartStock() {
        let count = 1;
        const cartItems = this.$store.state.cart.cartItems;
        for (const item of cartItems) {
          if (item.inStock === false) {
            count = 0;
          }
        }
  
        return count;
      },
      validateCartAndRemoveOutStock() {
        openModal(Modal, {
          modalTitle: "Some of your cart Items are out of stock",
          modalMessage: "<p>Some of your cart Items are out of stock</p> <br> <p>This action will remove the item from the cart.</p>",
          requestType: true
        })
        .then(() => {
          this.removeOutStockFromCart();
        })
        .catch(() => {
        });
      },
      removeOutStockFromCart() {
        const cartItems = this.$store.state.cart.cartItems;
        for (const item of cartItems) {
          if (item.inStock === false) {
            this.removeFromCart(item.id, item.itemId);
          }

          this.redirectToProducts();
        }
      },
      removeFromCart(Id, itemId) {
        const isLoggedIn = this.$store.getters["auth/getLoginUserStatus"];
        const guestToken = localStorage.getItem('guestToken');
        if (isLoggedIn || guestToken !== null) {
          cartService.removeCart({
            Id: Id,
          }).then((response) => {
            if (response.data.StatusCode === 1) {
              this.$store.dispatch('cart/removeFromCart', itemId);
            }
          })
          if (this.$route.name === 'checkout' || this.$route.name === 'guestCheckout') {
            this.redirectToProducts();
          }
        }
      },
      getCartTotalQuantity() {
        return this.$store.getters["cart/cartTotalQuantity"];
      },
      redirectToProducts() {
        setTimeout(() => {
          if (this.getCartCount() === 0) {
            showOverlay();
            this.$router.push("/products");
          }
        }, 2000);
      },
       getShippingSettings() {
        this.$axios.get(this.$API_URL + 'product/shipping-settings', { headers: authHeader() })
        .then((response) => {
          if (response.data.StatusCode === 1) {
            const primaryDimensions = response.data.Data.dimensions.find(dimension => dimension.IsPrimary);
            const primaryWeights = response.data.Data.weights.find(weight => weight.IsPrimary);
            const shippingOrigin = response.data.Data.shippingOrigin;
  
            this.$store.dispatch('checkout/submitData', { key: 'setting', data: {
                weight: primaryWeights,
                dimensions: primaryDimensions,
                origin: shippingOrigin
              }
            });
          }
        }).catch((error) => {
          console.error(error);
        })
      },
      async saveCarierrData() {
        try {
          const serviceData = await shipstationService.getServicesListing();
          const serviceNames = serviceData.map(service => ({ ServiceName: service.name }));

          if (serviceNames.length > 0) {
            await this.$axios.post(this.$API_URL + 'product/shipping-services', serviceNames, { headers: this.$authHeader() });
          }
        } catch (error) {
          console.error('Error saving shipping services data:', error);
        }
      },

      getApplepayData() {
        const deliveryData = this.$store.getters['checkout/getDeliveryData'];
        const data = {};
        if (deliveryData !== null) {
          data.deliveryData = {
            label: deliveryData.service,
            amount: deliveryData.price,
            identifier: deliveryData.serviceCode,
            detail: deliveryData.service,
          }
        }
        data.amount = this.getCartTotal();
  
        return data;
      },
    }
}

