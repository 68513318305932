const stripe = require('stripe')(process.env.VUE_APP_STRIPE_ACCOUNT);
class StripeService {
  /**
   * Creates a new customer with the given name and email.
   *
   * @param {string} name - the name of the customer
   * @param {string} email - the email of the customer
   * @return {object} the created customer object
   */
  createCustomer(name, email, phone) {
    return stripe.customers.create({
      name:name,
      email: email,
      phone: phone
    });
  }
  /**
   * createSource - A function to create a source for a customer.
   *
   * @param {type} customerId - The ID of the customer
   * @param {type} sourceData - The data for the source
   * @return {type} The created source
   */
  createSource(customerId, sourceData) {
    return  stripe.customers.createSource(customerId,sourceData);
  }

  /**
   * Creates a Stripe token using the provided source data.
   *
   * @param {Object} sourceData - The data used to create the Stripe token
   * @return {Object} The created Stripe token
   */
  createStripeToken(sourceData){
    return stripe.tokens.create(sourceData);
  }

  /**
   * Deletes a specific source for a customer.
   *
   * @param {string} customerId - The ID of the customer
   * @param {string} sourceId - The ID of the source to be deleted
   * @return {object} The deleted source object
   */
  deleteSpecificSource(customerId, sourceId) {
    return stripe.customers.deleteSource(customerId,sourceId);
  }
  
  /**
   * Processes a payment using a card for a given amount, customer, card, currency, description, and email.
   *
   * @param {number} amount - The amount to be charged
   * @param {string} customerId - The ID of the customer
   * @param {string} cardId - The ID of the card to be used for payment
   * @param {string} currency - The currency for the payment (optional, defaults to 'usd')
   * @param {string} description - The description of the payment
   * @param {string} email - The email address for receipt
   * @return {object} The payment intent object
   */
  payOrderPaymentbyCard(
    amount,
    customerId,
    cardId,
    currency,
    description,
    email,
  ) {
    return stripe.paymentIntents.create({
      amount: this.convertChargeAmount(amount),
      customer: customerId,
      payment_method: cardId,
      currency: currency || 'usd',
      description: description,
      receipt_email: email,
      payment_method_types: ['card'],
      statement_descriptor_suffix: 'Pregame',
    });
  }

  /**
   * A function to confirm an intent.
   *
   * @param {type} id - the id of the intent
   * @return {type} the confirmation result
   */
  confirmIntent(id){
    return stripe.paymentIntents.confirm(id);
  }
  
  /**
   * Convert the charge amount to an integer by multiplying it by 100 and flooring the result.
   *
   * @param {number} charge - the amount to be converted
   * @return {number} the converted charge amount
   */
  convertChargeAmount(charge) {
    return Math.floor(charge * 100);
  }
  
  /**
   * Updates the name of a customer.
   *
   * @param {string} customerId - The ID of the customer
   * @param {string} firstName - The first name of the customer
   * @param {string} lastName - The last name of the customer
   * @return {object} The updated customer object
   */
  updateCustomerName(customerId,fistName,lastName){
    return  stripe.customers.update(
      customerId,
      {
        name: fistName+" "+lastName,
      }
    );
  }

  async refundPayment(paymentIntentId,refundAmount){
    return await stripe.refunds.create({
            payment_intent: paymentIntentId,
            amount:this.convertChargeAmount(refundAmount)
    });
  }
}

export default new StripeService();
