import SSHeaders from '/src/services/shipstation-header.js';
import axios from 'axios';
const SS_API_URL = process.env.VUE_APP_SHIPSTATION_HOST;

class ShipStationService {
    
  async getCarriersListing() {
    const response = await axios.get(SS_API_URL + 'carriers', { headers: SSHeaders() });
    return response.data;
  }

  async getServicesListing() {
    try {
      const response = await axios.get(SS_API_URL + 'carriers/listservices?carrierCode=ups_walleted', { headers: SSHeaders() });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async getShippingRates(data, setting) {
    try {
      const payload = {
        carrierCode: "ups_walleted",
        serviceCode: null,
        packageCode: null,
        fromPostalCode: setting.origin.ZipPostalCode,
        weight: {
          value: (setting.weight.Dimension.toFixed(2) * data.totalQuantity),
          units: setting.weight.Name.replace('(s)', 's').toLowerCase()
        },
        dimensions: {
          // units: setting.dimensions.Name.replace('(s)', 's').toLowerCase(),
          // length: setting.dimensions.Dimension,
          units: "inches",
          length: 0,
          width: 0,
          height: 0
        },
        confirmation: "delivery",
        ...data 
      };

      const response = await axios.post(SS_API_URL + 'shipments/getrates', payload, { headers: SSHeaders() });
      return response.data;

    } catch (error) {
      console.error(error);
    }
  }
}

export default new ShipStationService();
