<script setup>
import Header from "@/components/layouts/HeaderView.vue";
import Footer from "@/components/layouts/FooterView.vue";
import {useMenuItems, getGeneralSettings } from "@/utilites/hooks";
</script>

<style lang="scss">
@import '@/assets/sass/main.scss';
</style>


<template>
    <Header 
      v-if="generalSettings.header_logo"
      :items="menuItems"
      :settings="generalSettings"
    />
    <RouterView />
    <Footer 
      v-if="generalSettings.footer_logo"
      :items="menuItems"
      :settings="generalSettings"
    />
</template>

<script>

export default {
  name: 'PageLayout',
  data() {
    return {
      menuItems: [],
      generalSettings: [],
    };
  },
  async mounted() {
    const { items} = useMenuItems();
    this.menuItems = items;
    
    const { settings} = getGeneralSettings();
    this.generalSettings = settings;
  }
}

</script>