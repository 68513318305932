<template>
  <div class="container relative">
    <div class="header-search">
      <div class="flex items-center justify-between">
        <input
          ref="searchInput"
          class="search-input"
          type="text"
          v-model="search"
          v-on:keyup="keymonitor"
          placeholder="Search"
        />

        <button @click="reDirectToSearchPage" class="search-btn">
          <img src="@/assets/images/arrow.svg" alt="" />
        </button>
      </div>
      <div class="search-list">
        <div v-for="result in searchResults" :key="result.ProductId" class="">
          <a class="text-white" :href="'/product/' + result.ProductId">
            {{ result.Name }}
          </a>
        </div>
        <button
          class="page-found"
          @click="reDirectToSearchPage"
          v-if="pages.length"
        >
          {{ pages.length }} page are found
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { searchProducts } from "@/utilites/helpers";
import { getSearchData } from "@/utilites/hooks";
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
export default {
  props: {
    toggleSearchBar: Function,
  },
  components: {},
  name: "searchInput",
  data() {
    return {
      search: "",
      products: [],
      searchResults: [],
      pages: [],
      productId: [],
    };
  },
  mounted() {
    this.$refs.searchInput.focus();
    if (this.$isVisible) {
      this.fetchProducts();
    }
    this.searchPage();
  },
  methods: {
    async keymonitor(event) {
      if (event.key == "Backspace" && this.search == "") {
        this.searchResults = [];
      } else if (event.key == "Enter") {
        const { matchingProducts } = await searchProducts(
          this.search,
          this.products
        );
        this.searchResults = matchingProducts;
        this.searchPage().then(() => {
          this.reDirectToSearchPage();
        });
      } else {
        const { matchingProducts } = await searchProducts(
          this.search,
          this.products
        );
        this.searchResults = matchingProducts;
      }
    },
    reDirectToSearchPage() {
      this.toggleSearchBar();
      if (this.search) {
        this.$router.push("/search-result-page?search=" + this.search);
      }
    },
    async fetchProducts() {
      try {
        const response = await axios.get(API_URL + "product/all");
        const products = response.data.Data.products;
        this.products = products;
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
    async searchPage() {
      if (this.search) {
        getSearchData(this.search)
          .then(({ pageData }) => {
            this.pages = pageData;
          })
          .catch((error) => {
            console.error("Error fetching product listing data:", error);
          });
      }
    },
  },
};
</script>
