import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Notifications from 'vue3-vt-notifications'
import axios from 'axios';
import Loading from 'vue3-loading-screen';
import { createHead } from '@unhead/vue'
import DisableAutocomplete from 'vue-disable-autocomplete';
// import vue3GoogleLogin from 'vue3-google-login';
// import VueAppleLogin from 'vue-apple-login';
// import VueSocialSharing from 'vue-social-sharing'
import AlertMessages from './components/alerts/AlertMessages.vue';
import ToastNotification from './components/alerts/ToastNotification.vue';
import i18n from './i18n';
import authHeader from '/src/services/auth-header.js';
import animateTextMixin from '@/utilites/mixins/animatedText';
import resizeImageMixin from '@/utilites/mixins/resizeImage';
import getCartDataMixin from '@/utilites/mixins/getCartData';
import { createModal } from '@kolirt/vue-modal';
import { modalConfig } from './utilites/modalConfig'
import {generateSessionIdAndStore} from '@/utilites/helpers';
import {getIsVisible} from '@/utilites/helpers';
import Toast from "vue-toastification";

// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

// const d = new Date();
// let time = d.getTime();
const app = createApp(App)

app.mixin(animateTextMixin);
app.mixin(resizeImageMixin);
app.mixin(getCartDataMixin);

// Generate aad store session ID
generateSessionIdAndStore();

app.config.globalProperties.$axios      = axios;
app.config.globalProperties.$API_URL    = process.env.VUE_APP_API_URL;
app.config.globalProperties.$authHeader = authHeader;
app.config.globalProperties.$isVisible  = getIsVisible();
app.config.globalProperties.$captchaKey  = process.env.VUE_APP_RECAPTCHA_SITE_KEY;

// let gauthClientId    = process.env.VUE_APP_GOOGLE_CLIENT_ID;
// let appleCLientId    = process.env.VUE_APP_APPLE_CLIENT_ID;
// let appleRedirectUri = process.env.VUE_APP_APPLE_REDIRECT_URL;

app.use(Notifications);
app.use(Loading, {
    bg: '#ffffff26',
    slot: `<div class="success">Please Wait. We are processing your request.</div>`,
})

app.use(DisableAutocomplete);
// app.use(vue3GoogleLogin, {
//   clientId: gauthClientId
// })

// app.use(VueAppleLogin, {
//   clientId: String(appleCLientId),
//   scope: 'name email',
//   redirectURI: appleRedirectUri,
//   state: time.toString(),
//   usePopup: true,
// });

app.component('alert-messages', AlertMessages);
app.component('ToastNotification', ToastNotification);

const head = createHead()
app.use(createModal(modalConfig))
app.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 5,
  newestOnTop: true
});
// app.use(VueSocialSharing);
app.use(head)
app.use(store)
app.use(router)
app.use(i18n())
app.mount('#app')
