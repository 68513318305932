<template>
  <div class="dropdownCart dropdown header-cart-dropdown">
    <img src="@/assets/images/dropdown-before.png" class="dropdown-before" alt="" />
    <div class="cart-dropdown-wrapper scroll">
      <div class="grid grid-cols-1 items-start">
        <div class="checkout-bag">
          <div class="flex items-center justify-between px-4 pt-3 pb-6" :class="getCartCount() >0 ? 'cart_header':''">
            <h5>Cart</h5>
            <a class="close" @click="toggleCartDropdown()">
              <img src="@/assets/images/close.svg" alt="Close | Pregame" />
            </a>
          </div>
          <div class="cart-container" v-if="getCartCount() >0">
            <!-- <h6>Order Details</h6> -->
            <div class="order-details" :style="item.inStock === false ? 'opacity: 0.8' : ''" v-for="item in getUserCartListing()" :key="item.itemId">
              <div class="name">
                <p>{{ item.name }}</p>
                <p class="font-semibold">${{ (item.price * item.quantity).toFixed(2) }}</p>
              </div>
              <div class="image" style="position: relative;">
                <img :src="item.image" :alt="item.name+ ' | Cart' " />
                <div class="cartOutStock" v-if="item.inStock === false">
                  <p>Out of Stock</p>
                </div>
              </div>
              <div class="order-all-details">
                <div @click="deleteModal(item.id, item.itemId)" title="Remove this item from your bag" class="removecart mr-5">
                  <img src="@/assets/images/remove-cart.svg" alt="Remove Cart">
                </div>
                <div class="colors mr-2">
                  <div class="colors-circle">
                    <span class="" :style="{ 'background-color': item.colorRgb }"></span>
                    <p>{{ item.sizeText }}</p>
                  </div>
                </div>
                <div class="flex items-center">
                  <div class="count">
                    <div class="quantity-selector">
                      <button @click="decrement(item)" :disabled="item.quantity <= item.minCartItem || item.inStock === false"><img src="@/assets/images/minus.svg" alt="" /></button>
                      <input type="number" readonly v-model="item.quantity" :min="item.minCartItem" :max="item.maxCartItem" />
                      <button @click="increment(item)" :disabled="item.quantity >= item.maxCartItem || item.inStock === false"><img src="@/assets/images/plus.svg" alt="" /></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="order-summary">
              <div class="amount" >
                <p>Subtotal</p>
                <p>
                  ${{ this.getCartSubTotal() }}
                </p>
              </div>
              <div class="amount">
                  <p>Shipping</p>
                  <p>$0</p>
                </div>
                <div class="amount">
                  <p>Tax</p>
                  <p>$0</p>
                </div>
                <div class="amount">
                  <h6>Order Total</h6>
                  <h6>
                    ${{ this.getCartTotalExclTax() }}
                  </h6>
                </div>
            </div>
          </div>
          <div v-else>
            <p class="cart-empty order-details">Your cart is empty</p>
          </div>
        </div>
        <a v-if="this.getCartCount() >0" @click="loginState()" class="btn btn-primary checkoutBtn">Checkout</a>
        <a href="/products" v-else class="btn btn-primary checkoutBtn">SEE All PRODUCTS</a>
      </div>
    </div>
  </div>
  <ToastNotification v-if="this.errorOutStock" :message="this.errorOutStock" type="error" :timeout="3000" />

</template>

<script>
import { openModal } from "@kolirt/vue-modal";
import Modal from "@/components/modals/Modal.vue";
import cartService from "@/services/cart.service";
export default {
  name: 'CartDropDown',
  data() {
    return {
      quantity: 1,
      checkCartLength: 0,
      errorOutStock: null,
    };
  },
  methods: {
    deleteModal(id, localId) {
      openModal(Modal, {
        modalTitle: "Delete Item form cart",
        modalMessage: "Are you sure to want to delete this cart item?",
      })
      .then(() => {
        this.removeFromCart(id, localId);
      })
      .catch(() => {
        console.log("catch");
      });
    },
    toggleCartDropdown() {
      this.$emit('close-cart-dropdown');
    },
    increment(item) {
      this.errorOutStock = null;
      item.quantity += 1;
      if (item.quantity >= item.maxCartItem) {
        this.errorOutStock = "Max item quantity is " + item.maxCartItem+ " allowed to be added in your cart";
      } else if (item.quantity <= item.minCartItem) {
        this.errorOutStock = "Min item quantity is " + item.minCartItem+ " allowed to be added in your cart";
      } else {
        this.updateQuantity(item, item.quantity);
      }
    },
    decrement(item, request) {
      if (item.quantity > 1) {
          item.quantity -= 1;
          if (!request && request !== 'manual') {
            this.updateQuantity(item, item.quantity);
          }
      }
    },
    isLoggedIn() {
      return this.$store.getters["auth/getLoginUserStatus"];
    },
    updateQuantity(item, quantity) {
      const isLoggedIn = this.isLoggedIn()
      const guestToken = localStorage.getItem('guestToken');
      const itemId = item.itemId;

      if (isLoggedIn || guestToken !== null) {
        cartService.updateCart({
          ItemId: item.id,
          Quantity: quantity,
        }).then((response) => {
          if (response.data.StatusCode === 1) {
            this.$store.dispatch('cart/updateQuantity', { itemId, quantity });
          } else {
            if (response.data.Message === "item_cannot_be_added_in_cart") {
              this.errorOutStock = response.data.Data.warnings[0] || null;
            }
            
            if (item.quantity > item.maxCartItem) {
              item.quantity = item.maxCartItem;
            }

            if (item.quantity < item.minCartItem) {
              item.quantity = item.minCartItem;
            }
            this.decrement(item, 'manual');
          }
        })
      }
    },

    loginState() {
      if (this.checkCartStock() === 0) {
        this.validateCartAndRemoveOutStock();
      } else {
        if (this.isLoggedIn()) {
          this.toggleCartDropdown();
          this.$router.push("/checkout");  
        } else {
          this.$router.push("/guest-checkout");
          this.$store.dispatch("auth/setLoginCheckoutState", true);
          this.toggleCartDropdown();
        }
      }
    },
    getCartCount() {
      return this.$store.getters["cart/cartCount"];
    },
  },
}
</script>