export default {
  methods: {
    /**
     * Resizes an image based on the provided height.
     *
     * @param {string} imageUrl - The URL of the image to be resized.
     * @param {number} height - The desired height of the resized image.
     * @return {string} The URL of the resized image.
     */
    resizeImage(imageUrl, height) {
        const baseUrl = String(process.env.VUE_APP_BUTTER_CMS_CDN);
        
        return `${baseUrl}/resize=height:${height}/${imageUrl.split(baseUrl)[1]}`;
    },
    getCurrentTheme() {
      const elements = document.getElementsByTagName('html');
      const htmlElement = elements[0];
      const theme = htmlElement.getAttribute('data-theme');
      
      return (theme === 'light') ? 'light' : 'dark';
    }
  }
}

