<script>
import PageLayout from "./components/layouts/PageLayout.vue";

export default {
  name: "App",
  components: {
    PageLayout,
  }
};
</script>

<template>
  <ModalTarget />
  <PageLayout />
</template>
