<template>
    <p v-if="message" :class="{ 'error': isError, 'success': isSuccess }" v-html="message"></p>
  </template>
  
  <script>
  export default {
    props: {
      message: String,
      isError: { 
        type: Boolean,
        default: false
      },
      isSuccess: { 
        type: Boolean,
        default: false
      },
    }
  };
  </script>